import Box, { BoxProps } from "@mui/material/Box";

import { Typography } from "@noticia/ui";

import RewardIcon from "~/components/icons/RewardIcon";

type Props = BoxProps & {
	variant: "filled" | "outlined";
	label: string | number;
};

const RewardChip = (props: Props) => {
	const { variant, label, ...boxProps } = props;

	return (
		<Box
			display="flex"
			alignItems="center"
			sx={(theme) => ({
				width: "min-content",
				borderRadius: 4,
				padding: theme.spacing(0.5, 1, 0.5, 1),
				...(variant === "filled"
					? {
							backgroundColor: theme.palette.neutral[25],
						}
					: {
							border: `1px solid ${theme.palette.neutral[200]}`,
						}),
			})}
			{...boxProps}
		>
			<RewardIcon sx={{ mr: 0.75 }} />
			<Typography variant="body4" fontWeight="regular" color="navyBlue.900">
				{typeof label === "number"
					? new Intl.NumberFormat("fr-FR").format(label)
					: label}
			</Typography>
		</Box>
	);
};

export default RewardChip;
